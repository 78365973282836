.resources-page {
  .reusable-container {
    margin-bottom: 27px;
    .orange {
      color: var(--color-pac-orange);
    }
  }
  padding: 1rem 0;

  .table-card {
    border: 1px solid var(--color-pac-border-grey1);
    border-radius: 5px;
    margin-bottom: 1em;

    .table-text-area {
      padding: 1em 1em 0.5em 1em;
    }
  }

  .table-responsive {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .resource-table {
    margin-bottom: 0px;

    thead.resource-table-head {
      background: var(--color-pac-purple-dark);
    }

    tr {
      border-bottom : 1px solid var(--color-pac-border-grey1);
    }

    tr:last-child {
      border-bottom: none;
    }

    tbody tr:nth-of-type(odd) {
      background-color: #f6f6f6;
    }

    tbody tr:nth-of-type(even) {
      background-color: var(--color-white);
    }
    .th-action {
      text-align: center;
    }
    th {
      font-family: var(--body-font);
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 28px;
      letter-spacing: 0.015em;
      color: var(--color-white);
    }

    td {
      font-family: var(--body-font);
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      letter-spacing: 0.015em;
      color: var(--color-black1);
      border-top: none;
      // padding: 6px 15px 6px 15px;
      padding: .45rem .75rem;
    }

    span.resource-dwnld-bth {
      font-family: var(--body-font);
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      letter-spacing: 0.015em;
      color: var(--color-black1);
    }

    .action-td {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      border-top: none;
    }
  }
  // title,describe.button,links font here
  h2.table-title {
    font-family: var(--body-font);
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 28px;
    color: var(--color-black1);
  }
  p.table-describe {
    font-family: var(--body-font);
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    display: flex;
    align-items: center;
    letter-spacing: 0.015em;
    color: var(--color-black1);
  }

  button.resource-dwnld-bth.btn.btn-link {
    font-family: var(--body-font);
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.015em;
    color: var(--color-black1);
  }

  button.resource-lang-bth.btn.btn-link {
    font-family: var(--body-font);
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.015em;
    color: var(--color-pac-orange);
    &:focus {
      box-shadow: none;
    }
  }
    @media (min-width: 992px) {
      padding: 2.3125rem 0;
    }
}
