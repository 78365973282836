.launch-guide-section {
    margin-bottom: 115px;
    .orange {
      color: var(--color-pac-orange);
    }
    .orange.video-link{
      a, a:active {
        color: var(--color-pac-orange);
      }
    }
    .cta-box {
      max-width: 940px;
      margin: 30px auto 116px auto;
        .content {
            .org-edit-title-area {
              .org-edit-form-title {
                font-size: 2.5rem;
                font-family: var(--title-font);
                margin-bottom: 0px;
                word-wrap: break-word;
                .pac-tootip-icon {
                  margin-left: 2px;
                  top: -10px;
                }
              }
              img.warning {
                margin-bottom: 15px;
                margin-right: 15px;
              }
            }
            .title-description {
              .note {
                font-weight: 600;
              }
            }
            .pac-code {
              display: inline-block;
              background-color: var(--color-grey-bg1);
              padding: 20px;
              margin-top: 1rem;
              margin-bottom: 26px;
              .icon-container {
                display: inline-block;
                padding-right: 20px;
                vertical-align: top;
                img {
                  width: 61px;
                }
              }
              .description-container {
                display: inline-block;
                width: calc(100% - 90px);
                text-align: left;
                .title {
                  font-size: 1.625rem;
                  font-family: var(--title-font);
                  color: var(--color-black1);
                  word-wrap: break-word;
                }
                .desc {
                  font-family: var(--body-font);
                  .pac_code_desc {
                    margin-bottom: 10px;
                    a, a:active {
                      color: var(--color-pac-orange);
                    }
                    .copy-code-container {
                      cursor: pointer;
                      img {
                        vertical-align: bottom;
                      }
                    }
                  }
                }
              }
            }

            .numbered-list {
              margin-bottom: 15px;
              .list-group {
                list-style: decimal inside;
                .list-group-item {
                  display: list-item;
                  padding-left: 0px;
                  border: none;
                  font-size: 22px;
                  font-weight: 600;
                }
              }
              .custom-checkbox .custom-checkbox{
                label.custom-control-label {
                  padding-left: 5px;
                }
              }
            }
        }
    }
}
