.create-org-page {

  padding-top: 22px;

  /* Global CSS, you probably don't need that */

  .clearfix:after {
    clear: both;
    content: "";
    display: block;
    height: 0;
  }

  select {
    padding: 8px 36px 8px 16px;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url('~/assets/images/dropdown_icon.svg');
    background-repeat: no-repeat;
    background-size: 16px;
    background-position: 95% 50%;
  }

  .container {
    width: 1000px;
    margin: 0 auto;
  }

  .wrapper {
    display: table-cell;
    height: 400px;
    vertical-align: middle;
  }

  .nav {
    margin-top: 40px;
  }

  .pull-right {
    float: right;
  }

  a, a:active {
    color: #333;
    text-decoration: none;
  }

  a:hover {
    color: #999;
  }

  /* Breadcrups CSS */

  .arrow-steps .step {
    font-size: 2.5rem;
    font-family: var(--title-font);
    text-align: left;
    color: var(--color-pac-purple-dark);
    cursor: default;
    margin: 0 6px;
    padding: 20px 10px 10px 30px;
    width: 31%;
    height: 140px;
    float: left;
    position: relative;
    background-color: var(--color-pac-purple-light);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; 
    transition: background-color 0.2s ease;
    .step-text {
      font-size: 2.5rem;
      line-height: 1.2;
      font-family: var(--title-font);
      color: var(--color-white);
      img {
        width: 23px;
        height: 23px;
        margin-left: 5px;
      }
    }
    .sub-text {
      font-size: 1.375rem;
      font-family: var(--body-font);
      color: var(--color-white);
      font-weight: 700;
    }
    &.step2, &.step3 {
      padding-left: 70px;

    }
  }

  

  .arrow-steps .step:after,
  .arrow-steps .step:before {
    content: " ";
    position: absolute;
    top: 0;
    right: -44px;
    width: 0;
    height: 0px;
    border-top: 69px solid transparent;
    border-bottom: 71px solid transparent;
    border-left: 45px solid var(--color-pac-purple-light);
    z-index: 2;
    transition: border-color 0.2s ease;
  }

  .arrow-steps .step:before {
    right: auto;
    left: 0;
    border-left: 45px solid #fff; 
    z-index: 0;
  }

  .arrow-steps .step:first-child:before {
    border: none;
  }

  .arrow-steps .step:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  .arrow-steps .step span {
    position: relative;
  }

  .arrow-steps .step span:before {
    opacity: 0;
    content: "✔";
    position: absolute;
    top: -2px;
    left: -20px;
  }

  .arrow-steps .step.done span:before {
    opacity: 1;
    -webkit-transition: opacity 0.3s ease 0.5s;
    -moz-transition: opacity 0.3s ease 0.5s;
    -ms-transition: opacity 0.3s ease 0.5s;
    transition: opacity 0.3s ease 0.5s;
  }

  .arrow-steps .step.current {
    background-color: var(--color-pac-purple-dark);
  }

  .arrow-steps .step.current:after {
    border-left: 45px solid var(--color-pac-purple-dark);
  }

  .org-create-page {
    .orange {
      color: var(--color-pac-orange);
    }
    .cta-box {
      max-width: 940px;
      margin: 30px auto 116px auto;
      .content {
        .org-edit-title-area {
          .org-edit-form-title {
            font-size: 2.5rem;
            font-family: var(--title-font);
            margin-bottom: 0px;
            word-wrap: break-word;
            .pac-tootip-icon {
              margin-left: 2px;
              top: -10px;
            }
          }
          img.warning {
            margin-bottom: 15px;
            margin-right: 15px;
          }
        }
        .title-description {
          .note {
            font-weight: 600;
          }
        }
        .org-sizes {
          display: inline-block;
          .org-size-card {
            margin-right: 23px;
            display: inline-block;
          }
        }
        .form-group {
          .form-control {
            height: 50px;
          }
        }
        .are-you-sure {
          display: inline-block;
          background-color: var(--color-grey-bg1);
          padding: 20px;
          margin-top: 1rem;
          margin-bottom: 42px;
          .icon-container {
            display: inline-block;
            padding-right: 20px;
            vertical-align: top;
            img {
              width: 68px;
            }
          }
          .description-container {
            display: inline-block;
            width: calc(100% - 90px);
            text-align: left;
            .title {
              font-size: 1.625rem;
              font-family: var(--title-font);
              color: var(--color-black1);
              word-wrap: break-word;
            }
            .desc {
              font-family: var(--body-font);
              .contact-us {
                color: var(--color-pac-orange);
              }
            }
          }
        }
        .numbered-list {
          margin-bottom: 15px;
          .list-group {
            list-style: decimal inside;
            .list-group-item {
              display: list-item;
              padding-left: 0px;
              border: none;
              font-size: 22px;
              font-weight: 600;
            }
          }
          .custom-checkbox .custom-checkbox{
            label.custom-control-label {
              padding-left: 5px;
            }
          }
        }
        .pac-code {
          display: inline-block;
          background-color: var(--color-grey-bg1);
          padding: 20px;
          margin-top: 1rem;
          margin-bottom: 26px;
          .icon-container {
            display: inline-block;
            padding-right: 20px;
            vertical-align: top;
            img {
              width: 61px;
            }
          }
          .description-container {
            display: inline-block;
            width: calc(100% - 90px);
            text-align: left;
            .title {
              font-size: 1.625rem;
              font-family: var(--title-font);
              color: var(--color-black1);
              word-wrap: break-word;
            }
            .desc {
              font-family: var(--body-font);
              .pac_code_desc {
                margin-bottom: 10px;
                a, a:active {
                  color: var(--color-pac-orange);
                }
                .copy-code-container {
                  cursor: pointer;
                  img {
                    vertical-align: bottom;
                  }
                }
              }
            }
          }
        }
        .selected-plan-container {
          margin-top: 14px;
          height: 280px;
          margin-bottom: 20px;
          .selected-card{
            display: inline-block;
            width: auto;
            height: 100%;
            padding: 10px 21px;
            background-color: var(--color-grey-bg1);
            .org-size-card {
              cursor: default;
            }
            .spinner {
              text-align: center;
              min-height: 100px;
              color: var(--color-pac-purple-dark);
            }
            .your-plan {
              padding-bottom: 10px;
              font-size: 0.875rem;
              font-weight: 600;
            }
            .org-size-card{
              margin: auto;
              width: 174px;
            }
            .change-ur-plan {
              text-align: center;
              padding-top: 10px;
              a {
                cursor: pointer;
              }
            }
          }
          .enterprise-div {
            display: inline-block;
            width: calc(100% - 270px);
            height: 100%;
            vertical-align: bottom;
            margin-left: 20px;
            padding: 10px 30px;
            background-color: var(--color-grey-bg1);
            padding-top: 33px;
            .title {
              font-size: 1.625rem;
                font-family: var(--title-font);
            }
          }
        }
        .payment-link-btn {
          button {
            img {
              vertical-align: top;
              margin-left: 5px;
            }
          }
        }
      }
    }
  }
}

.modal{
  .changePlanModal {
    max-width: 939px;
    .modal-content {
      .modal-body {
        padding: 26px 46px;
        .org-sizes {
          display: inline-block;
          .org-size-card {
            width: 174px;
            margin-right: 22px;
            display: inline-block;
            &:last-child {
              margin-right: 0px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  .create-org-page {
    .arrow-steps .step {
      padding: 8px 10px 0px 30px;
      width: 30%;
      .step-text {
        font-size: 1.5rem;
      }
      .sub-text {
        font-size: 1rem;
      }
      &.step2, &.step3 {
        padding-left: 48px;

      }
    }
  }
}

@media only screen and (max-width: 625px) {
  .create-org-page {
    .org-create-page {
      .cta-box {
        .content {
          .selected-plan-container {
            height: 100%;
            .selected-card {
              width: 100%;
            }
            .enterprise-div {
              display: block;
              width: 100%;
              margin-left: 0px;
              height: 100%;
              vertical-align: none;
              margin-top: 10px;
              padding: 10px 20px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .create-org-page {
    .arrow-steps .step {
      padding: 8px 0px 0px 10px;
      width: 27%;
      .step-text {
        font-size: 0.8rem;
      }
      .sub-text {
        font-size: 0.8rem;
      }
      &.step2, &.step3 {
        padding-left: 48px;
        overflow-wrap: break-word;
      }
    }
  }
}