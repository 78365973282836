:root {
  // Colors
  --color-white: #ffffff;
  --color-box-shadow-grey: #cccccc;
  --color-grey-bg: #FAFAFA;
  --color-pac-purple-dark: #50287D;
  --color-pac-border-grey: #C4C4C4;
  --color-pac-border-grey1: #D4D4D4;
  --color-black1: #333333;
  --color-input-border-grey: #FF0000;
  --color-black: #000000;
  --color-pac-orange: #F57F29;
  --color-pac-green: #56C568;
  --color-pac-red: #EB5757;
  --color-pac-error-red: #ff0000;
  --color-pac-link-blue: #3FA2F7;
  --color-pac-purple-light: #927EB4;
  --color-dropdown-mene-border: #EBEBEB;
  --color-grey-bg1: #F6F6F6;
  --color-dark-grey: #EAEAEA;
  --color-dark-grey2: #525252;

  // Fonts
  --body-font: "Open Sans", sans-serif;
  --title-font: "Cooper Std Black", sans-serif;
}
