.btn.button-outline {
  font-family: var(--body-font);
  background: var(--color-white);
  border: 1px solid var(--color-pac-purple-dark);
  border-radius: 24px;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  color: var(--color-pac-purple-dark);
  min-width: 13rem;

  &:active,
  &:focus,
  &:hover,
  &:active:focus,
  &:not(:disabled):active,
  &:not(:disabled):active:focus {
    color: var(--color-pac-purple-dark);
    background: var(--color-white);
    border: 1px solid var(--color-pac-purple-dark);
    box-shadow: 1px 1px 10px 1px var(--color-box-shadow-grey);
  }
}

.btn.button-purple {
  font-family: var(--body-font);
  background: var(--color-pac-purple-dark);
  border: 1px solid var(--color-pac-purple-dark);
  border-radius: 24px;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 22px;
  color: var(--color-white);
  min-width: 13em;
  padding: 0.5em 1.5em;

  &:active,
  &:focus,
  &:hover,
  &:active:focus,
  &:not(:disabled):active,
  &:not(:disabled):active:focus {
    color: var(--color-white);
    background: var(--color-pac-purple-dark);
    border: 1px solid var(--color-pac-purple-dark);
    box-shadow: 1px 1px 10px 1px var(--color-box-shadow-grey);
  }

  .spinner-border {
    width: 1.2rem;
    height: 1.2rem;
    margin-left: 0.3rem;
    font-size: 0.6rem;
  }
}

.btn.button-purple-outline {
  width: 205px;
  height: 50px;
  font-family: var(--body-font);
  background: var(--color-white);
  border: 1px solid var(--color-pac-purple-dark);
  border-radius: 24px;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 22px;
  color: var(--color-pac-purple-dark);
  padding: 0.5em 1.5em;

  &:active,
  &:focus,
  &:hover,
  &:active:focus,
  &:not(:disabled):active,
  &:not(:disabled):active:focus {
    color: var(--color-white);
    background: var(--color-pac-purple-dark);
    border: 1px solid var(--color-pac-purple-dark);
    box-shadow: 1px 1px 10px 1px var(--color-box-shadow-grey);
  }

  .spinner-border {
    width: 1.2rem;
    height: 1.2rem;
    margin-left: 0.3rem;
    font-size: 0.6rem;
  }
}
