.org-size-card {
  width: 174px;
  height: 190px;
  padding: 12px;
  background-color: var(--color-white);
  border-radius: 5px;
  border: 1px solid var(--color-pac-border-grey);
  position: relative;
  padding: 1.25rem 0.5rem 0.5rem;
  border-radius: 5px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 10px 0px 12px 0px;
  text-align: center;
  &.selected {
    border: 4px solid var(--color-pac-purple-dark);
    padding-top: 1.063rem;
  }

  &.no-border {
    border: none;
  }

  .icon-container {
    flex-direction: row;
    align-items: center;
    .icon-row.prize-text{
      .prize-text-value, .extension{
        display: inline-block;
        margin-top: 8px;
        font-weight: 600;
        color: var(--color-black1);
        line-height: 2.25rem;
      }
      .prize-text-value{
        font-family: var(--title-font);
        font-size: 1.625rem;
      }
      .extension{
        font-family: var(--body-font);
        font-size: 0.875rem;
      }
    }
  }

  .title, .subtitle {
    font-family: var(--body-font);
    font-weight: 700;
    font-style: normal;
    font-size: 0.875rem;
    line-height: 1.5rem;
    color: var(--color-pac-purple-dark);
    margin: 0px;
  }

  .select-btn, .select-btn-contactUs{
    display: block;
    cursor: pointer;
    position: absolute;
    bottom: 5px;
    left: 50%;
    transform: translateX(-50%);
    .select-btn-text{
      font-family: var(--body-font);
      background: var(--color-pac-purple-dark);
      border: 1px solid var(--color-pac-purple-dark);
      border-radius: 24px;
      font-style: normal;
      font-weight: 600;
      font-size: 1rem;
      line-height: 22px;
      color: var(--color-white);
      width: 6.8125rem;
      padding: 0.5em 1.5em;
    }
  }

  .select-btn.no-display{
    display: none;
  }

  .select-btn-contactUs{
    width: 172px;
    .select-btn-text{
      position: relative;
      bottom: 40px;
      padding: 0.5rem 1rem;
    }
  }

  // .subtitle {
  // 	font-size: 0.875rem;
  // 	color: var(--color-black);
  // }
}

.org-size-card.last-card{
  padding-top: 2.063rem;
}

.org-size-card.padding{
  padding-top: 2.5rem;
}

btn.org-size-card:hover, btn.org-size-card:focus {
  background-color: none;
  border-color: none;
  box-shadow: none;
  text-decoration: none;
}

.btn-link.org-size-card:hover, .btn-link.org-size-card:focus {
  text-decoration: none;
  background-color: none;
  border-color: none;
  box-shadow: none;
}
 
