/* Global Styles */

/*========== font family adding ================  */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700;800&family=Montserrat:wght@400;500;600;700&display=swap');
@font-face {
  font-family: "Cooper Std Black";
  font-style: normal;
  font-weight: normal;
  font-display: auto;
  src: url('~/assets/fonts/copper-std-black.woff') format("woff");
}
@import 'styles/variables';

body {
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  font-family: var(--body-font);
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#app {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.page-container {
  overflow: auto;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  background: var(--color-grey-bg);
}

.page-container .page-content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

@import 'styles/custom-radio';
@import 'styles/bootstrap-tooltip-customization';
@import 'styles/form';
@import 'styles/app-buttons';
@import 'styles/custom-table';
