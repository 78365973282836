.footer-container {
  background-color: var(--color-pac-purple-light);
  padding: 1rem;

  .trademark-text {
    font-style: normal;
    font-weight: bold;
    font-size: 0.9375rem;
    line-height: 28px;
    display: flex;
    letter-spacing: 0.015em;
    color: var(--color-white);
    border-bottom: 1px solid white;
  }

  .footer-links-area {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;

    .footer-links {
      display: inline-block;
      font-style: normal;
      font-weight: bold;
      font-size: 0.9375rem;
      line-height: 200%;
      align-items: center;
      color: var(--color-white);
      text-decoration: none;
    }
  }

  @media (min-width: 576px) {
    .trademark-text {
      border-bottom: none;
    }

    .footer-links-area {
      align-items: flex-end;
    }
  }

  @media (min-width: 768px) {
    .footer-links-area {
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;

      .footer-links {
        margin-left: 1rem;
      }
    }
  }
}
