.auth-container {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
  @media (min-width: 992px) {
    padding: 3rem 0;
  }

  .email-register-form-container,
  .email-login-form-container,
  .login-register-social-page,
  .register-social-page,
  .forgot-password-page,
  .reset-password-page {
    flex: 1;

    .forget-password-button-text {
      font-family: var(--body-font);
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 28px;
      text-align: right;
      letter-spacing: 0.015em;
      color: var(--color-pac-orange);
      text-decoration: none;
    }

    .form-title {
      font-family: var(--title-font);
      font-style: normal;
      font-weight: bold;
      font-size: 30px;
      line-height: 36px;
      color: var(--color-black1);
      margin-bottom: 0px;
    }

    .form-describe {
      font-family: var(--body-font);
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 28px;
      letter-spacing: 0.015em;
      color: var(--color-black1);
      margin-bottom: 1.2rem;
      margin-top: 1rem;
    }

    .login-links-container,
    .register-links-container {
      display: flex;
      flex-direction: column;
      align-items: center;

      .social-links {
        display: table;

        > div {
          width: 100%;
          button {
            width: 100%;
          }
        }
      }
    }

    .register-links-container {
      margin-top: 0.825rem;
    }

    .register-link,
    .login-link {
      font-family: var(--body-font);
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 28px;
      letter-spacing: 0.015em;
      color: var(--color-pac-orange);
      margin-left: 10px;
      text-decoration: none;
    }

    .register-form-title,
    .email-login-form-title,
    .forgot-pasw-form-title,
    .email-form-register-title,
    .reset-form-title {
      font-family: var(--body-font);
      font-style: normal;
      font-weight: bold;
      font-size: 22px;
      line-height: 28px;
      color: var(--color-black1);
      margin-bottom: 5px;
    }

    .forgot-password-area {
      margin-top: 1.5em;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }

    .resend-code-link {
      font-family: var(--body-font);
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 28px;
      display: flex;
      align-items: center;
      letter-spacing: 0.015em;
      color: var(--color-pac-orange);
      justify-content: center;
      margin-top: 6px;
    }

    img.pac-icon {
      width: 28px;
      height: 21px;
    }

    .have-verification-code-link {
      font-family: var(--body-font);
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 28px;
        letter-spacing: 0.015em;
        color: var(--color-pac-orange);
    }

    li.password-suggestion-li {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 28px;
      letter-spacing: 0.015em;
      color: var(--color-black1);
      display: block;
      margin-left: 0.5rem;
    }
  }

  .reset-password-page.container-fluid {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (min-width: 992px) {
    .login-register-social-page,
    .register-social-page,
    .forgot-password-page,
    .reset-password-page {
      .form-title {
        font-size: 2.5rem;
      }
    }

    .email-register-form-container,
    .email-login-form-container,
    .forgot-password-page,
    .reset-password-page {
      max-width: 550px;
    }
  }
}
