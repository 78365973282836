.about-this-challenge-card {
    padding: 20px 10px 25px 10px;
    background: var(--color-white);
    border: 1px solid var(--color-pac-border-grey1);
    border-radius: 5px;
    .title-text {
        padding-bottom: 16px;
        font-family: var(--body-font);
        font-weight: 600;
        font-style: normal;
        font-size: 1.125rem;
        line-height: 1.5rem;
        text-align: center;
        color: var(--color-black1);
    }
    .icon-container {
        display: inline-block;
        width: 50%;
        text-align: center;
        vertical-align: middle;
        min-height: 95px;
        .first-section {
            display: flex;
            align-items: flex-end;
            justify-content: center;
            padding-bottom: 10px;
            &.title {
                margin: auto;
                font-family: var(--title-font);
                color: var(--color-pac-purple-dark);
                font-size: 1rem;
                font-style: normal;
                font-weight: 800;
            }
            img.card-icon {
                height: 62px;
                width: 76px;
            }
        }
        .second-section {
            height: 20px;
            color: #666666;
            line-height: 1;
        }
    }
    .view-resource-div {
        text-align: center;
        padding-top: 22px;
        .view-resource {
            background: var(--color-white);
            border: 1px solid var(--color-pac-purple-dark);
            box-sizing: border-box;
            border-radius: 24px;
            a {
                color: var(--color-pac-purple-dark);
            }
        }
    }

    .add-opacity-for-upcoming-challenge {
        opacity: 0.3;
        pointer-events: none;
    }
}