.unconfirmed-page {
  flex: 1;
  padding: 2rem 0;

  .cta-box {
    max-width: 848px;
  }

  .UnconfirmedContainer-card {
    background: var(--color-white);
    border: 1px solid var(--color-pac-border-grey1);
    border-radius: 5px;
    max-width: 800px;
    padding: 4em 4em;
  }

  .invited_title {
    font-family: var(--body-font);
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 28px;
    color: var(--color-black1);
  }
  .invited_text {
    font-family: var(--body-font);
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.015em;
    color: var(--color-black1);
  }
  .invite-button-atea {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .invite-button {
    padding-left: 3em;
    padding-right: 3em;
  }
  .contactus-link {
    font-family: var(--body-font);
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.015em;
    color: var(--color-pac-orange);
  }
  .contactus-extra-text {
    font-family: var(--body-font);
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.015em;
  }

  .find-email-text {
    font-family: var(--body-font);
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.015em;
    color: var(--color-black);
    margin-top: 1em;
  }

  .contact-area {
    margin-top: 1em;
  }
  /* =====================all media quries goes from here====================== */


  /* Large devices (desktops, 992px and up) */
  @media (min-width: 992px) {
    padding: 4.25rem 2rem;
  }
}
