
/* Booststrap Tooltip customization starts */
.bs-tooltip-top.tooltip.show {
  opacity: 1;
}

.bs-tooltip-auto[x-placement^=top] .arrow::before,
.bs-tooltip-top .arrow::before {
  /*border-color: var(--color-grey-bg);*/
  border-top-color: var(--color-pac-border-grey);
}

.bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  border-bottom-color: var(--color-pac-border-grey);
}

.bs-tooltip-auto[x-placement^=left] .arrow::before {
  border-right-color: var(--color-pac-border-grey);
}

.bs-tooltip-auto[x-placement^=right] .arrow::before {
  border-left-color: var(--color-pac-border-grey);
}

.tooltip-inner {
  padding: 1rem;
  text-align: left;
  color: var(--color-black1);
  max-width: 400px;
  border-radius: 5px;
  background-color: var(--color-grey-bg);
  border: 1px solid var(--color-pac-border-grey);

  .help-text-with-icon {
    display: flex;

    img {
      margin-right: 0.5rem;
    }

    &.icon-right {
      flex-direction: row-reverse;
    }
  }
}
