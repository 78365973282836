.no-launch-data {
  padding: 12px;
  position: fixed;
  width: 100%;
  z-index: 9;
  background: #FFEECA;
  left: 0;
  border-bottom: solid 1px var(--color-pac-orange);
  .text {
    margin-right: 8px;
  }
  img {
    width: 21px;
    height: 17px;
    margin: 0 8px;
  }
  .button-purple.btn {
    background-color: var(--color-pac-orange);
    border: 1px solid var(--color-pac-orange);
    padding: 4px 10px;
    min-width: 10rem;
    &:hover {
      background-color: var(--color-pac-orange);
      border: 1px solid var(--color-pac-orange);
    }
  }
}

.page-home {
  padding: 1rem 0;
  background-color: #FAFAFA;

  &.orange-toaster {
    margin-top: 60px;
  }

  &.no-data {
    position: relative;
    .add-opacity {
      opacity: 0.3;
      pointer-events: none;
    }
    .not-available-div {
      position: absolute;
      width: 530px;
      background: white;
      left: calc(50% - 265px);
      top: 305px;
      background: #FFFFFF;
      box-shadow: 0px 0px 10px 6px rgb(0 0 0 / 9%);
      border-radius: 5px;
      padding: 50px;
      padding-top: 35px;
      .orange {
        color: var(--color-pac-orange);
      }
      .image-div {
        text-align: center;
        margin-bottom: 10px;
        img {
          width: 65px;
          height: 53px;
        }
      }
      .copy-code {
        color: var(--color-pac-orange);
        cursor: pointer;
        &.no-data {
          img {
            width: 23px;
            height: 23px;
            object-fit: contain;
            margin-left: 0.1rem;
            margin-top: -6px;
          }
        }
      }
      .title {
        font-family: var(--title-font);
        text-align: center;
        font-size: 1.625rem;
        font-style: normal;
        font-weight: 800;
        line-height: 2.25rem;
        margin-bottom: 0;
      }
    }
  }

  .grey-button-outline {
    /* The button must be of `light` variant */
    border: 1px solid var(--color-pac-border-grey1);
    border-radius: 5px;
    background: var(--color-white);
  }

  .print-page-button {
    .spinner-border {
      height: 1rem;
      width: 1rem;
      font-weight: normal;
      border-width: 0.2rem;
      margin-left: 0.5rem;
      color: var(--color-pac-purple-dark);
    }
  }

  .page-title-container {
    color: var(--color-black1);
    align-items: flex-start;
    margin-bottom: 1rem;
    margin-top: 1rem;

    .title {
      font-family: var(--title-font);
      font-size: 1.625rem;
      font-style: normal;
      font-weight: 800;
      line-height: 2.25rem;
      margin-bottom: 0;
    }

    .copy-code-container {
      font-family: var(--body-font);
      font-size: 0.8rem;
      line-height: 1rem;
      font-style: normal;
      font-weight: 600;
      display: inline-flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      .pac-tootip-icon {
        margin-right: 0.2rem;
        height: 17px;
        width: 17px;
      }

      .copy-code {
        color: var(--color-pac-orange);
        font-size: 1rem;
        font-style: normal;
        font-weight: 600;
        line-height: 2.25rem;
        letter-spacing: 0em;
        text-align: right;
        margin-left: 0.5rem;
        cursor: pointer;

        img {
          width: 23px;
          height: 23px;
          object-fit: contain;
          margin-left: 0.1rem;
          margin-top: -6px;
        }
      }
    }
    .sub-title {
      font-family: var(--body-font);
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.815rem;
      letter-spacing: 0.015em;
      text-align: left;

    }
  }

  .section-container {
    margin-bottom: 0.8125rem;

    .header {
      padding-bottom: 0.5rem;
      border-bottom: 1px solid var(--color-box-shadow-grey);
      margin-bottom: 1rem;
    }

    .section-title {
      font-family: var(--body-font);
      font-weight: 600;
      font-style: normal;
      font-size: 1.375rem;
      line-height: 1.75rem;
      color: var(--color-black1);
      margin-right: 1rem;
    }
    .section-sub-title {
      font-family: var(--body-font);
      font-style: normal;
      font-size: 0.875rem;
      line-height: 2.25rem;
      color: var(--color-black1);
    }
  }

  .all-time-metric-container {
    .counter-card-container {
      margin-bottom: 1rem;
      .counter-card {
        height: 100%;
      }
    }
  }

  .date-based-reporting {
    .graph-col-container {
      margin-bottom: 1rem;
    }

    .cards-container {
      .counter-card {
        padding: 1.5rem 0;

        &:first-child {
          margin-bottom: 2rem;
        }
      }
    }

    .section-sub-title > span {
      color: #959595;
    }

    .graph-container {
      background-color: var(--color-white);
      padding: 1rem;
      border: 1px solid var(--color-pac-border-grey);

      #legend-container {
        padding-bottom: 10px;
      }

      .info-icon {
        position: absolute;
        top: 0.5rem;
        right: 1.6rem;
      }
    }
  }
  .organization-activity-breakdown {
    background-color: var(--color-white);
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: auto;

    .title {
      font-family: var(--body-font);
      font-weight: 600;
      font-style: normal;
      font-size: 1.125rem;
      line-height: 1.50rem;
      color: var(--color-black1);
      margin: 1rem;

      .info {
        width: 15px;
        margin-left: 0.5rem;
      }
    }
  }
  .organization-activity-breakdown-container {
    .sort-indicator {
      display: inline-block;
      margin-left: 0.5rem;
      width: 1rem;
      height: 1rem;
      object-fit: contain;
      transition: transform 0.5s;
      transform: rotate(0deg);

      &.asc {
        transform: rotate(180deg);
      }
    }

    table {
      border-bottom: none;
      margin-bottom: 0;

      thead {
        tr.statistics {
          th {
            padding: 0 0 0.8rem;
            vertical-align: top;

            /* TODO: using important because.. the commons.css has important. Fix that to remove this. */
            &:not(:first-child) {
              border-width: 1px 0 0 1px;
              border-style: solid;
              border-color: var(--color-pac-border-grey1);
            }

            &:first-child {
              border: 0;
            }

            .counter-card {
              padding: 1rem 2.5rem;
            }
          }
        }

        tr.dark {
          background-color: var(--color-dark-grey2);
          th {
            padding: 0.5rem 1rem;
            vertical-align: top;
            font-family: var(--body-font);
            font-weight: 400;
            font-style: normal;
            font-size: 1rem;
            line-height: 1.75rem;

            color: var(--color-white); /* TODO: Remove Style clashes in commons.css */
            text-transform: none; /* TODO: Remove Style clashes in commons.css */

            text-align: center;

            .btn {
              text-decoration: none;
              color: var(--color-white);
            }

            &:first-child {
              text-align: left;
            }

            /* Bordering as per the header card. Check design for this */
            &:first-child,
            &:nth-child(2n + 1) {
              border-right: 1px solid var(--color-pac-border-grey1);
            }

            .btn {
              padding: 0;
            }
          }
        }
      }
      tbody {

        tr {
          border-bottom: none;

          td {
            font-family: var(--body-font);
            font-weight: 400;
            font-style: normal;
            font-size: 1rem;
            line-height: 1.75rem;
            color: var(--color-black1);
            text-align: center;
            padding: 0.5rem 1rem;
            border-top-color: var(--color-pac-border-grey1);
            &:first-child {
              text-align: left;
            }

            /* Bordering as per the header card. Check design for this */
            &:first-child,
            &:nth-child(2n + 1) {
              border-right: 1px solid var(--color-pac-border-grey1);
            }
          }
        }

        tr:nth-child(2n + 1) td {
          background-color: var(--color-grey-bg1);
        }

        tr.table-secondary td {
          background-color: var(--color-dark-grey);
          font-weight: 700;
        }
      }
    }
  }
}

/* =====================all media quries goes from here====================== */

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .page-home {
    .section-container {
      .section-title,
      .section-sub-title {
        display: inline-block;
      }
    }

    .page-title-container {
      .copy-code-container {
        font-size: 1rem;
        line-height: 2.25rem;
        justify-content: flex-end;
      }
    }

    .date-based-reporting {
      .date-range-selector {
        display: inline-block;
        min-width: 170px;
      }

      .cards-container {
        display: flex;
        flex-direction: column;
        .counter-card, .counter-card:first-child {
          flex: 1;
          margin-bottom: 1rem;
          justify-content: center;
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .page-home {
    padding: 1rem 0 4rem 0;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}

@media only screen and (max-width: 600px) {
  .page-home {
    &.no-data {
      .not-available-div {
        width: 400px;
        padding-top: 10px;
        left: calc(50% - 200px);
      }
    }
  }
}

@media only screen and (max-width: 560px) {
  .page-home {
    &.orange-toaster {
      margin-top: 100px;
    }
  }
}

