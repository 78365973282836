.manage-teams-container {
  padding: 1rem 0;

  .group-td-action-area {
    padding-left: 1rem;
    .group-td-delete-btn {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 28px;
      letter-spacing: 0.015em;
      margin-right: 10px;
      cursor: pointer;
      color: var(--color-pac-purple-dark);
    }
  }
  
  @media (min-width: 992px) {
    padding: 2.3125rem 0;
  }
}
