.toaster-container {
  display: flex;
  justify-content: flex-start;
  position: fixed;
  top: 0;
  width: 100%;
  background: var(--color-grey-bg1);
  z-index: 1031;
  min-height: 44px;

  .toaster-text {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    display: flex;
    align-items: center;
    color: var(--color-black1);
    margin: 10px 0px;
  }

  .checkmark-icon {
    max-width: 25px;
    margin-right: 1em;
    margin-left: 1em;
  }

  .toaster-text-area {
    display: inline-block;
  }

  .toaster-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}
