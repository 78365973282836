.not-found-page {
  padding: 1rem 0;

  h3.not-found-title {
    font-family: Cooper;
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 36px;
    color: var(--color-black1);
  }

  p.not-found-describe {
    font-family: var(--body-font);
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.015em;
    color: var(--color-black1);
    margin-top: 1rem;
  }

  @media (min-width: 992px) {
    padding: 2.3125rem 0;
  }
}
