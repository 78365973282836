.cta-box {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 0;
  width: 100%;
  max-width: 700px;

  .content {
    position: relative;
    background: var(--color-white);
    border-radius: 5px;
    width: 100%;
    padding: 1.5rem 1rem;

    &.has-border {
      border: 1px solid var(--color-pac-border-grey1);
    }

    @media (min-width: 992px) {
      padding: 2.56rem 4.325rem 3rem ;
    }
  }

  &.mini-padding {
    .content {
      padding: 1rem 1rem;
      @media (min-width: 992px) {
        padding: 2.56rem 3rem 3rem;
      }
    }
  }

  .back-button-text {
    margin-right: auto;
    padding-right: 0;
    padding-left: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 0.9375rem;
    line-height: 20px;
    padding: 0 0 0.3rem 0.2rem;
    letter-spacing: -0.015em;
    color: var(--color-pac-link-blue);
    border: none;
    outline: none;
    background-color: transparent;

    &:focus,
    &:hover,
    &:active {
      text-decoration: none;
    }
  }
}
