.modal-container {
  .modal-title {
    font-family: var(--title-font);
    font-style: normal;
    font-weight: 800;
    font-size: 26px;
    line-height: 36px;
    text-align: center;
    color: var(--color-black1);
    margin-top: 1rem;
  }

  .modal-content {
    min-height: 20em;
    background: var(--color-white);
    box-shadow: 0 0 10px 6px rgba(0, 0, 0, 0.09);
    border-radius: 5px;
  }

  .modal-footer {
    justify-content: center;
    border-top-color: white;
    margin-bottom: 2em;
  }

  button.btn.btn-outline-primary {
    padding: 5px 40px;
  }

  .modal-close-icon-area {
    padding: 15px 15px;
    text-align: right;
    position: relative;
  }

  .describe-text-1 {
    font-family: var(--body-font);
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.015em;
    color: var(--color-black1);
    padding: 5px 4em;
    margin-bottom: 5px;
  }

  .ul-area {
    margin: 0 2.5rem 0 0;
  }

  .describe-text-2 {
    font-family: var(--body-font);
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.015em;
    color: var(--color-black1);
    margin-bottom: 5px;
  }

  .describe-list-text {
    font-family: var(--body-font);
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.015em;
    color: var(--color-black1);
    display: flex;
    padding: 0;
    margin-bottom: 0.3rem;
  }

  .invite-email-input {
    background: var(--color-white);
    border: 1px solid rgba(0, 0, 0, 0.5);
    margin-top: 1.2em;
    border-radius: 0px;
  }

  .invite-form-area {
    padding: 0 20px;
  }

  p.gale-text {
    font-family: var(--body-font);
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: var(--color-black1);
  }

  .admin-email-input {
    padding: 0px 4em;
  }

  .modal-body {
    padding-bottom: 2em;
    padding-top: 2em;
  }

  .show-icon {
    height: 45px;
    width: 45px;
  }

  .show-icon-exclamation {
    height: 53px;
    width: 65px;
  }

  .show-icon-message {
    height: 37.98px;
    width: 56.2px;
  }

  img.modal-close-icon {
    height: 30px;
    width: 30px;
    cursor: pointer;
    position: absolute;
    right: 16px;
    top: 20px;
    z-index: 2;
  }

  /* =====================all media quries goes from here====================== */

  /* Small devices (landscape phones, 576px and up) */
  @media (min-width: 576px) {
  }

  @media (max-width: 575px) {
    .describe-text-1 {
      padding: 5px 1em;
    }
  }

  /* Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) {
  }

  @media (max-width: 767px) {
    .describe-text-1 {
      padding: 5px 0.5em;
    }
    .admin-email-input {
      padding: 0 0.5em;
    }
    .form-final-error-text {
      font-size: 13px;
      line-height : 0;
    }
  }
}
