.counter-card {
  background-color: var(--color-white);
  border-radius: 5px;
  border: 1px solid var(--color-pac-border-grey);
  position: relative;
  padding: 1.25rem 0.5rem 0.5rem;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  &.no-border {
    border: none;
  }

  .info-icon {
    object-fit: contain;
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
  }

  .icon-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    .icon-text {
      font-family: var(--body-font);
      font-weight: 600;
      font-style: normal;
      font-size: 0.875rem;
      line-height: 2.25rem;
      text-align: center;
      color: var(--color-black1);
      margin-left: 0.5rem;
    }
  }

  .icon-row {
    text-align: center;
    .card-icon {
      height: 35px;
      object-fit: contain;
    }
  }

  .title {
    font-family: var(--body-font);
    font-weight: 600;
    font-style: normal;
    font-size: 1.1rem;
    line-height: 1.5rem;
    text-align: center;
    color: var(--color-black1);
    margin: 0.7rem;
    &.national_average {
      margin: 1px;
    }
  }

  .count {
    font-family: var(--body-font);
    font-weight: 700;
    font-style: normal;
    font-size: 5.6rem;
    line-height: 6rem;
    text-align: center;
    color: var(--color-black1);
    &.national_average {
      line-height: 4rem;
    }

    .spinner-border {
      font-size: 1rem;
      margin: 1.5rem 0;
      color: var(--color-pac-purple-dark);
    }
  }

  .delta {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.5rem 0;

    &.up {
      color: var(--color-pac-green);
    }

    &.down {
      color: var(--color-pac-red);
    }

    .sign {
      width: 26px;
    }
  }

  .count-delta-same-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    .delta {
      margin-left: 1rem;
    }
  }

  .count-title {
    font-family: var(--body-font);
    font-weight: 400;
    font-style: normal;
    font-size: 1.125rem;
    text-align: center;
    color: #666666;
    // margin-bottom: 1.2rem;
  }

  .national-average {
    width: 100%;
    border-top: solid 1px var(--color-pac-error-red);
    padding-top: 10px;
    text-align: center;
    .image-div {
      display: inline-block;
    }
    .title_text {
      padding-left: 7px; 
      display: inline-block;
    }
    .count_num {
      padding-left: 7px; 
      display: inline-block;
      color: var(--color-pac-error-red);
      font-weight: 700;
    }
  }

  /* Large devices (desktops, 992px and up)*/
  @media (min-width: 992px) {
    margin-bottom: 0;
  }
}

