.btn.button-social.btn-secondary {
  display: flex;
  align-items: center;
  background: var(--color-white);
  border: 1px solid var(--color-pac-border-grey1);
  border-radius: 5px;
  margin-bottom: 1rem;
  cursor: pointer;
  min-width: 19em;
  padding: 1rem 2.75rem;
  color: var(--color-black1);

  &:active,
  &:hover,
  &:focus,
  &:active:focus {
    outline: none;
    border: 1px solid var(--color-pac-border-grey1);
    background: var(--color-white);
    color: var(--color-black1);
    box-shadow: 0 0 3px var(--color-box-shadow-grey);
  }

  .social-button-text {
    font-size: 1.125rem;
    line-height: 25px;
    letter-spacing: 0.01rem;
    margin-left: 0.3rem;
  }

  img.social-image {
    width: 25px;
    height: 25px;
    object-fit: contain;
  }
}
