.header-nav-wrapper {
  .navbar-light .navbar-nav .nav-link {
    color: var(--color-white);
    padding: 10px 40px;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    background: var(--color-pac-purple-dark);
    border-radius: 20px;
  }
  .navbar-light .navbar-nav .nav-link.hide-button {
    display: none;
  }

  img.d-inline-block.align-top.pac-plus-logo {
    height: 38px;
    width: 49px;
  }

  .navbar-area {
    position: relative;
    background-color: var(--color-pac-purple-light);
    padding: 4px 15px;
  }

  .brand-text-bold {
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: var(--color-white);
    margin-left: 20px;
    text-decoration: none;
    padding: 16px 0;
    position: relative;

    &:active,
    &:focus,
    &:hover {
      color: var(--color-white);
    }
  }

  .brand-text-normal {
    font-weight: normal;
  }

  .fr-button-text {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    align-items: center;
    letter-spacing: -0.015em;
    color: #18a0fb;
  }

  .sub-brand-text {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 28px;
    display: flex;
    align-items: center;
    letter-spacing: 0.015em;
    color: var(--color-pac-purple-dark);
    margin-left: 20px;
    border-bottom: 4px solid transparent;

    &:active,
    &:focus,
    &:hover {
      color: var(--color-pac-purple-dark);
    }
  }

  .brand-text-logout {
    font-weight: 600;
    color: var(--color-pac-orange);
  }

  .nav-links {
    justify-content: flex-start;
  }

  .myprofile-nav-button {
    background: var(--color-white);
    border: 1px solid var(--color-pac-border-grey);
    border-radius: 8px;
  }

  .sub-navbar-area {
    background-color: var(--color-grey-bg1);
  }

  /* dropdown related changes */

  .dropdown {
    position: relative;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: var(--color-dropdown-mene-border);
    min-width: 250px;
    z-index: 1;
    top: 50px;
    right: 1px;
  }

  .dropdown-content a {
    padding: 9px 9px;
    text-decoration: none;
    display: block;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: var(--color-pac-purple-dark);
    background-color: white;
    cursor: pointer;
  }

  .dropdown-content a:hover {
    background-color: #ddd;
  }

  .dropdown-content.manage-organization-dropdown-menu {
    left: 0;
  }

  .dropdown:hover .dropdown-content {
    display: block;
    z-index: 99;
  }

  .gale-navbar-text {
    margin-right: 20px;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 30px;
    color: var(--color-white);
    display: flex;
    align-items: center;
    .org-name {
      font-weight: 600;
      padding-left: 5px;
    }
  }

  .profile-button-link {
    display: flex;
    align-items: center;
    text-decoration: none;
  }

  .myprofile-nav-button {
    background: var(--color-pac-purple-dark);
    border-radius: 50px;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: var(--color-white);
    text-decoration: none;
    padding: 10px 20px;
    border-color: var(--color-pac-purple-dark);
  }

  .sub-nav-links-area.navbar-nav {
    margin-left: 7em;
    button.sub-brand-text {
      &.align-right {
        position: absolute;
        right: 0px;
        background: transparent;
        border: none;
        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
    }
  }

  .background-color-added {
    background: var(--color-pac-purple-dark);
  }

  .active-link {
    border-color: var(--color-pac-purple-dark);
  }

  .responsive-navlink-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    margin-top: 1em;
  }

  .in-active {
    display: none;
  }

  .link-title {
    font-family: var(--body-font);
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 36px;
    letter-spacing: -0.015em;
    color: #f5f5f5;
  }

  .sublink-title {
    font-family: var(--body-font);
    color: #f8f9faa6;
    margin-bottom: 1em;
    flex-grow: 0;
    margin: 0px 0px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 36px;
    letter-spacing: -0.015em;
  }

  .dropdown-content {
    background: var(--color-white);
    border: 1px solid #eaeaea;
    border-radius: 5px;
  }

  .profile-nav-link {
    text-decoration: none;
    background: var(--color-pac-purple-dark);
    border-radius: 20px;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: var(--color-white);
    padding: 6px 20px;
    margin: 5px 0;
  }

  .profile-nav-link.disabled {
    background: transparent;
    border: 1px solid var(--color-white);
  }

  .profile-nav-link .white-user-icon {
    margin-right: 9px;
  }

  .vector-image {
    bottom: -4px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
  }

  a.brand.navbar-brand.brand-text {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: var(--color-white);
    margin-left: 2em;
  }

  /*nav,  burger btn open and close related changes  */

  .burger-btn-area {
    background: var(--color-white);
    border: 1px solid var(--color-pac-border-grey);
    box-sizing: border-box;
    padding: 9px;
  }

  img.burger-close-btn {
    width: 30px;
    height: 20px;
  }

  img.burger-btn {
    height: 40px;
    width: 40px;
  }
  .nav-link-area-close {
    display: none;
  }

  span.toggle-image-area {
    cursor: pointer;
  }

  .responsive-unauthorized-home-link {
    margin-right: auto;
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 20px;
    color: var(--color-white);
  }

  .right-arrow-image {
    height: 11px;
    width: 6px;
    margin-right: 8px;
    vertical-align: middle;
    margin-bottom: 2px;
  }

  img.user-image {
    height: 26px;
    width: 26px;
    margin-right: 8px;
    margin-left: 10px;
  }

  .profile-nav-link-active {
    background: var(--color-pac-purple-light);
    border: 1px solid var(--color-white);
    border-radius: 50px;
  }

  /* =====================all media quries goes from here====================== */

  /* Small devices (landscape phones, 576px and up) */
  @media (min-width: 576px) {
  }

  @media (max-width: 575px) {
    .invite-button {
      padding-left: 2em;
      padding-right: 2em;
    }
  }

  /* Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) {
  }

  @media (max-width: 767px) {
  }

  /* Large devices (desktops, 992px and up) */
  @media (min-width: 992px) {
    span.toggle-image-area {
      display: none;
    }

    .navbar-area {
      background-color: var(--color-pac-purple-light);
      padding: 0.25rem 1rem;
    }

    .sub-navbar-area {
      padding-top: 0;
    }

    .responsive-unauthorized-home-link {
      display: none;
    }

    .responsive-nav-links-area {
      display: none;
    }
    .regiester-responsive {
      display: none;
    }
  }

  @media (max-width: 991px) {
    .non-responsivenav-area {
      display: none;
    }
  }

  /* Extra large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {
  }
}
