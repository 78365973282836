.manage-groups-container {
  padding: 1rem 0;
  flex: 1;
  .overview-list {
    padding: 0px;
  }
  .header-description {
    padding-left: 0px;
  }
  .groups-container {
    margin-top: 4em;
  }

  p.profile-title {
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: -0.015em;
    color: var(--color-black);
  }
  .custom-table {
    margin-top: 1rem;
  }

  .table-title-container {
    justify-content: space-between;
    display: flex;
  }
  .table-container-title {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 36px;
    /* identical to box height, or 200% */
    letter-spacing: -0.015em;

    color: var(--color-black);
  }

  .describe-input {
    height: 100px;
  }

  .group-edit-delete-btn-area {
    margin-top: 0.5em;
  }

  .add-group-page-container {
  }

  .add-group-page-container .form-container {
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
  }

  .add-group-button-area {
    justify-content: center;
    align-items: center;
    display: flex;
    height: 100%;
  }

  .group-page {
    display: flex;
    align-items: unset;
    flex-direction: column;

    table {
      tbody {
        tr td {
          padding: 1rem;
        }
      }
    }
  }

  .group-form-title {
    font-family: var(--body-font);
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 28px;
    color: var(--color-black1);
  }

  .group-add-edit-form-container {
    // width: 500px;
  }

  .group-td-edit-btn {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 0.015em;
    color: var(--color-pac-orange);
    margin-right: 10px;
    cursor: pointer;
  }

  .group-td-delete-btn {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 0.015em;
    margin-right: 10px;
    cursor: pointer;
    color: var(--color-pac-purple-dark);
  }

  .group-td-action-area {
    display: flex;
    justify-content: space-between;
    padding: 1rem 2rem;
  }

  .group-edit-delete-btn-area {
    margin-top: 1em;
    display: flex;
    justify-content: flex-end;
  }

  a.group-edit-page-delete-btn {
    font-family: var(--body-font);
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    display: flex;
    align-items: center;
    text-align: right;
    letter-spacing: 0.015em;

    color: var(--color-input-border-grey);
  }

  img.delete-icon {
    height: 18px;
    width: 18px;
    margin-bottom: 4px;
    margin-right: 6px;
  }

  .group-edit-page {
    display: flex;
    justify-content: center;
    align-items: unset;
    /* margin-top: 3em; */
  }


  .tip-row {
    margin-top: 40px;
    padding: 2rem 1rem 2rem 2rem;
    background: var(--color-white);
    border: 1px solid var(--color-pac-border-grey1);
    border-radius: 5px;
    font-size: 0.875rem;
    .tip {
      padding-top: 20px;
      .icon {
        display: inline-block;
        vertical-align: top;
        width: 61px;
        height: 69px;
      }
      .description {
        display: inline-block;
        padding-left: 20px;
        max-width: 450px;
        .title {
          font-family: var(--title-font);
          font-weight: 600;
          font-style: normal;
          font-size: 1.625rem;
          color: var(--color-black);
        }
        ul {
          padding-left: 20px; 
        }
      }
    }
    .image-div {
      text-align: center;
      img {
        max-width: 100%;
      }
    }
  }

  /* =====================all media quries goes from here====================== */

  /* Large devices (desktops, 992px and up) */
  @media (min-width: 992px) {
    padding: 2.3125rem 0;

    .custom-table {
      margin-top: 0;
    }
  }
}
