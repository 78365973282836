.custom-radio .custom-control-label::before {
  background: none;
  border: 0;
}

.custom-radio .custom-control-label::after {
  background: url('~/assets/images/radio-unselected.svg');
  border: 0;
  background-size: 100%;
  border-radius: 0;
}

.custom-radio .custom-control-input:checked~.custom-control-label::before {
  background: none;
  border: 0;
}

.custom-radio .custom-control-input:checked~.custom-control-label::after {
  background: url('~/assets/images/radio-selected.svg');
  border: 0;
  background-size: 100%;
  border-radius: 0;
}



.custom-checkbox .custom-control-label::before {
  background: none;
  border: 0;
}

.custom-checkbox .custom-control-label::after {
  background: url('~/assets/images/checkbox_unselected.svg');
  border: 0;
  background-size: 100%;
  border-radius: 0;
  width: 18px;
  height: 18px;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
  background: none;
  border: 0;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
  background: url('~/assets/images/checkbox_selected.svg');
  border: 0;
  background-size: 100%;
  border-radius: 0;
}

.custom-checkbox .custom-control-input:focus~.custom-control-label::before {
  box-shadow: none;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
  border-color: var(--color-box-shadow-grey);
  background-color: var(--color-box-shadow-grey);
  border-radius: 0px;
  width: 18px;
  height: 18px;
}

