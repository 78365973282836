.reusable-container {

  .content-title {
    font-family: var(--title-font);
    font-style: normal;
    font-weight: 800;
    font-size: 26px;
    line-height: 36px;
    color: var(--color-black1);
  }

  .content-describe {
    font-family: var(--body-font);
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.015em;
    color: var(--color-black1);
  }
}
