.participation-card {
    background: var(--color-white);
    border: 1px solid var(--color-pac-border-grey);
    box-sizing: border-box;
    border-radius: 5px;
    padding: 10px;
    position: relative;
    height: 110px;
    .info-icon {
        object-fit: contain;
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
    }
    .icon-container {
        display: inline-block;
        width: 55px;
        height: 40px;
        padding-right: 14px;
        img {
            object-fit: contain;
        }
    }
    .title {
        vertical-align: middle;
        padding-right: 10px;
        display: inline-block;
        width: 45%;
        font-size: 1rem;
    }
    .count {
        vertical-align: middle;
        display: inline-block;
        padding-right: 10px;
        width: 25%;
        font-size: 3.75rem;
        font-weight: 700;
        line-height: 1.3;
    }

    .average_milestone {
        .icon-container {
            width: 42px;
        }
        .count {
            text-align: center;
            width: 10%;
        }
        .title {
            line-height: 1.3;
            width: 57%;
        }
    }
    .dec_average_milestone {
        .icon-container {
            width: 42px;
        }
        .count {
            text-align: center;
            width: 10%;
            font-size: 2.625rem;
        }
        .title {
            line-height: 1.3;
            width: 57%;
            @media screen and (min-width: 1376px) and (max-width: 1500px){
                width: 50%;
            }
            @media screen and (min-width: 1700px) {
                width: 60%;
            }
        }
    }
    .completed_challenge {
        .count {
            min-width: 45%;
            text-align: center;
            width: unset !important;
        }
        .title {
            line-height: 1.3;
            width: 40%;
        }
    }
    .average_per_person {
        .row1 {
            font-size: 1.125rem;
            font-weight: 600;
            img {
                width: 24px;
                margin-right: 8px;
            }
        }
        .row2 {
            display: inline-block;
            width: 100%;
            .move-min {
                line-height: 1;
                display: inline-block;
                width: 70%;
                vertical-align: top;
                .count-num {
                    display: inline-block;
                    text-align: center;
                    font-size: 3.125rem;
                    font-weight: 600;
                    min-width: 60%;
                }
                .text-val {
                    display: inline-block;
                    color: #666666;
                    padding-left: 5px;
                    width: 20%;
                }
            }
            .national-avg {
                display: inline-block;
                width: 30%;
                text-align: center;
                line-height: 1.3;
                border-left: solid 1px var(--color-pac-error-red);
                .icon {
                    img {
                        width: 16px;
                    }
                }
                .text-val {
                    font-size: 0.625rem;
                }
                .count-num {
                    color: var(--color-pac-error-red);
                }
            }
        }
    }
    .dec_average_per_person{
        .icon-container {
            width: 20%;
        }
        .title {
            line-height: 1.3;
            width: 70%;
        }
        .icon-title-container{
            width: 65%;
            display: inline-block;
            vertical-align: middle;
            padding-left: 10px;
        }
        .col2{
            width: 30%;
            display: inline-block;
            vertical-align: middle;
            .row1{
                width: 100%;
                display: inline-block;
                .move-min {
                    line-height: 1;
                    display: inline-block;
                    width: 100%;
                    vertical-align: top;
                    .count-num {
                        display: block;
                        text-align: center;
                        font-size: 2.625rem;
                        font-weight: 600;
                        min-width: 60%;
                    }
                }
            }
            .row2 {
                display: inline-block;
                width: 100%;
                .national-avg {
                    display: inline-block;
                    width: 100%;
                    text-align: center;
                    line-height: 1.3;
                    border-top: solid 1px var(--color-pac-error-red);
                    .icon {
                        img {
                            width: 16px;
                        }
                    }
                    .text-val {
                        font-size: 0.625rem;
                    }
                    .count-num {
                        color: var(--color-pac-error-red);
                    }
                }
            }
        }
    }
    .total_activity {
        .row1 {
            font-size: 1.125rem;
            font-weight: 600;
            img {
                width: 24px;
                margin-right: 8px;
            }
        }
        .row2 {
            display: inline-block;
            width: 100%;
            .move-min {
                min-width: 70%;
                line-height: 1;
                display: inline-block;
                vertical-align: top;
                .count-num {
                    display: inline-block;
                    font-size: 3.125rem;
                    font-weight: 600;
                    min-width: 60%;
                    text-align: center;
                }
                .text-val {
                    display: inline-block;
                    color: #666666;
                    padding-left: 5px;
                    max-width: 20%;
                }
            }
        }
    }
    .dec_total_activity{
        .icon-container{
            width: 15%;
            display: inline-block;
            vertical-align: middle;
            .card-icon{
                width: 47px;
            }
        }
        .dec_total_activity_col {
            width: 70%;
            display: inline-block;
            vertical-align: middle;
            text-align: center;
        }
        .row1 {
            font-size: 1rem;
            img {
                width: 24px;
                margin-right: 8px;
            }
        }
        .row2 {
            display: inline-block;
            width: 100%;
            .move-min {
                min-width: 70%;
                line-height: 1;
                display: inline-block;
                vertical-align: top;
                .count-num {
                    display: inline-block;
                    font-size: 2.625rem;
                    font-weight: 600;
                    min-width: 60%;
                    text-align: center;
                }
                .text-val {
                    display: inline-block;
                    color: #666666;
                    padding-left: 5px;
                    max-width: 20%;
                }
            }
        }
    }
}

.december-dash-normal-container {
    .participation-card {
        .normal {
            .count {
                font-size: 2.625rem;
                @media screen and (min-width: 992px) and (max-width: 1375px) {
                    font-size: 1.75rem;
                }
            }
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
    .participation-card{
        .dec_total_activity{
            .row2{
                .move-min{
                    .count-num{
                        font-size: 1.75rem;
                    }
                }
            }
        }
        .dec_average_per_person{
            .col2{
                .row1{
                    .move-min{
                        .count-num{
                            font-size: 1.75rem;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 992px) and (max-width: 1375px){ 
    .participation-card {
        .title {
            font-size: 0.8rem;
        }
        .count {
            font-size: 1.75rem;
            line-height: 3.3;
        }
        .dec_average_milestone{
            .title {
                font-size: 0.8rem;
            }
            .count {
                font-size: 1.75rem;
                line-height: 3.3;
            }
        }
        .average_per_person, .total_activity, .dec_total_activity {
            .row1 {
                font-size: 0.8rem;
            }
            .row2 {
                .move-min {
                    .count-num {
                        font-size: 1.75rem;
                    }
                    .text-val {
                        font-size: 0.8rem;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 992px) and (max-width: 1375px) {
    .participation-card{
        .dec_total_activity{
            .icon-container{
                .card-icon{
                    position: relative;
                    right: 8px;
                }
            }
            .row2{
                .move-min{
                    .count-num{
                        font-size: 1.75rem;
                    }
                }
            }
        }
        .dec_average_per_person{
            .title{
                position: relative;
                left: 10px;
            }
            .icon-title-container{
                padding-left: 0px;
            }
            .col2{
                position: relative;
                right: 10px;
                .row1{
                    .move-min{
                        .count-num{
                            font-size: 1.75rem;
                        }
                    }
                }
            }
        }
    }
}

.add-opacity-for-upcoming-challenge{
    opacity: 0.3;
    pointer-events: none;
}
