/* Form styles */
form {
  .input-label {
    font-family: var(--body-font);
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 0.015em;
    color: var(--color-black1);
    margin-bottom: 0px;
    display: flex;
    align-items: center;

    .pac-tootip-icon  {
      margin-left: 0.3rem;
    }
  }

  .highlight-input {
    border: 1px solid var(--color-pac-error-red);
  }

  ul {
    padding: 0;
  }

  .form-error-text,
  .form-final-error-text {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 0.015em;
    color: var(--color-pac-error-red);
    margin-bottom: 0.5rem;

    img.final-error-icon {
      height: 20px;
      width: 20px;
      margin-right: 0.5rem;
    }
  }
  .form-final-error-text {
    display: inline-block;

  }
}

