.pac-tootip-icon {
  position: relative;
  text-align: left;
  display: inline-flex;
  align-items: left;
  height: 15px;
  width: 15px;

  .tooltip-img {
    position: relative;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
