.slick-list {
    overflow: visible; 
}

.slides {
  position: relative;
  padding-left: 19px;
  .slick-prev, .slick-next {
    position: absolute;
    top: 50%;
  }
  .slick-prev {
    left: -6px;
    z-index: 999;
    &::before{
        color: #666666;
        content: '\003C';
    }
  }
  .slick-next {
    right: -5px;
    z-index: 999;
    &::before{
        color: #666666;
        content: '\003E';
    }
  }
}
.challenge-report-page-home {
    .challenge-section {
        padding-top: 15px;
        padding-bottom: 100px;
    }

    .challenge-section-spinner {
        text-align: center;
        padding-top: 50px;
    }
    .nav-section {
        width: calc(100% - 71px);
        margin: auto;
        height: 170px;
        overflow: hidden;
        .slick-slider.slides{
            .slick-prev {
                left: 0px;
            }
        }
        .nav-card-nonclickable {
            height: 134px;
            background-color: var(--color-grey-bg1);
            border: 0.75px solid var(--color-pac-border-grey1);
            box-sizing: border-box;
            border-radius: 5px;
            width: calc(100% - 20px)!important;
            text-align: center;
            opacity: 0.5;
            pointer-events: none;
            .image-div {
                height: calc(100% - 30px);
                display: flex;
                align-items: flex-end;
                padding-bottom: 15px;
                img{
                    filter: grayscale(100%);
                    padding-top: 15px;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
            .date {
                color: var(--color-pac-border-grey);
            }
        }
        .nav-card{
            height: 134px;
            background-color: var(--color-grey-bg1);
            border: 0.75px solid var(--color-pac-border-grey1);
            box-sizing: border-box;
            border-radius: 5px;
            width: calc(100% - 20px)!important;
            text-align: center;
            padding: 0 2px;
            &.selected {
                border: 0.75px solid var(--color-pac-purple-dark);
                background-color: var(--color-white);
                &:focus {
                    box-shadow:none;
                }
            }

            &.btn-link:hover {
                text-decoration: none;
            }
            &.btn-link:focus {
                text-decoration: none;
            }
            .image-div {
                height: calc(100% - 30px);
                display: flex;
                align-items: flex-end;
                padding-bottom: 15px;
                img{
                    padding-top: 15px;
                    margin-left: auto;
                    margin-right: auto;
                }
            }

            .date {
                height: 30px;
                color: var(--color-pac-purple-dark);
                font-size: 0.875rem;
            }

            .dot {
                width: 7px;
                height: 7px;
                border-radius: 4px;
                border-color: var(--color-pac-border-grey1);
                margin: 0 auto;
                margin-top: 20px;
                padding: 0px;
                background-color: var(--color-pac-border-grey1);
                &.selected {
                    background-color: var(--color-pac-purple-dark);
                    border-color: var(--color-pac-purple-dark);
                }

                &:focus {
                    outline: none;
                    box-shadow: none;
                }
            }
        }
    }
    .no-data-section {
        text-align: center;
        padding-top: 100px; 
    }
    .page-title-container {
        .title-section {
            .title {
                font-family: var(--title-font);
                font-size: 1.625rem;
                font-style: normal;
                font-weight: 800;
            }
            .sub-title {
                font-size: 1rem;
            }
        }
        .print-page-container {
            padding-right: 0px;

              .grey-button-outline {
                /* The button must be of `light` variant */
                border: 1px solid var(--color-pac-border-grey1);
                border-radius: 5px;
                background: var(--color-white);
              }

              .print-page-button {
                .spinner-border {
                  height: 1rem;
                  width: 1rem;
                  font-weight: normal;
                  border-width: 0.2rem;
                  margin-left: 0.5rem;
                  color: var(--color-pac-purple-dark);
                }
              }
        }
    }
    .top-section {
        .top-card{
            height: 100%;
            margin-bottom: 22px;
        }
        padding: 20px 0;
        .participation-card-container {
            .member {
                margin-top: 22px; 
            }
        }
        .counter-card-container {
            .counter-card  {
                padding: 18px 0;
                .count-delta {
                    .title {
                        font-size: 1.125rem;
                    }
                    .count {
                        font-size: 3.75rem;
                    }
                    .count-title {
                        font-size: 1rem;
                    }
                }
            }
            &.avg-activity {
                .count-title {
                    margin-bottom: 16px;
                }
            }
        }
    }
    .breakdown-section {
        .performance-breakdown {
            border: 1px solid var(--color-pac-border-grey1);
            border-radius: 5px;
            overflow: auto;
            .title-bar {
                .title {
                    &.dropdown {
                        width: 70%;
                    }
                    &.no-dropdown {
                        width: 100%;
                    }
                    display: inline-block;
                    font-weight: 600;
                    font-style: normal;
                    font-size: 1.625rem;
                    color: var(--color-black);
                    padding: 10px;
                    height: 85px;
                    .pac-tootip-icon {
                        margin-left: 10px;
                    }
                }
                .dropdown-div {
                    width: 30%;
                    display: inline-block;
                    vertical-align: top;
                    padding:10px;
                    .group-selector {
                        max-width: 172px;
                        margin: auto;
                        .css-2613qy-menu {
                            position: absolute;
                        }
                    }
                }
            }
            table {
                margin-bottom: 0px;
                thead {
                    background-color: var(--color-pac-purple-dark);
                    color: var(--color-white);
                    th {
                        border: solid 1px var(--color-white);
                        text-align: center;
                        font-weight: 400;
                        width: 24%;
                        vertical-align: middle;
                        &.col-title {
                            text-align: left;
                            width: 28%;
                            vertical-align: middle;
                        }

                        .btn {
                            text-decoration: none;
                            color: var(--color-white);
                            padding: 0px;
                            .sort-indicator {
                                display: inline-block;
                                margin-left: 0.5rem;
                                width: 1rem;
                                height: 1rem;
                                object-fit: contain;
                                transition: transform 0.5s;
                                transform: rotate(0deg);

                                &.asc {
                                    transform: rotate(180deg);
                                }
                            }

                            &:focus {
                                box-shadow: none;
                            }

                        }
                        &:first-child {
                            border-color: var(--color-white) var(--color-white) var(--color-white) var(--color-pac-border-grey1);
                        }
                        &:last-child {
                            border-color: var(--color-white) var(--color-pac-border-grey1) var(--color-white) var(--color-white);
                        }
                    }
                }
                tbody {
                    tr {
                        &.org-total {
                            td {
                                background-color: var(--color-dark-grey) !important;
                                font-weight: 700;
                            }
                        }
                        td {
                            text-align: center;
                            border: 1px solid var(--color-pac-border-grey1);
                            &.row-title {
                                text-align: left;
                            }

                            &:first-child,
                            &:nth-child(2n + 1) {
                              border-right: 1px solid var(--color-pac-border-grey1);
                            }
                        }
                    }
                    tr:nth-child(2n + 1) td {
                      background-color: var(--color-grey-bg1);
                    }
                }
            }
            .no-rows {
                text-align: center;
                padding: 10px;
                min-height: 50px;
                border: solid 0.5px var(--color-pac-border-grey1);
                border-radius: 0 0 5px 5px;
            }
        }
    }
}

.add-opacity-for-upcoming-challenge, .add-opacity-when-challenge-upcoming.breakdown-section{
    opacity: 0.3;
    pointer-events: none;
}

// CSS Styling that handles the Modal for Challenge Reporting not available
.modal-preChallenge{
  display: block;
  width: 540px;
  max-width: 100%;
  position: fixed;
  z-index: 100;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: white;
  box-shadow: 0 0 10px 6px rgb(0 0 0 / 9%);
  border-radius: 5px;
  padding: 15px 35px;
  .modal-image-container{
    display: block;
      img{
        display: block;
        margin: 20px auto 10px auto;
        width: 65px;
        height: 53px;
      }
    }
    .modal-header-text, .modal-desc{
        p{
            color: var(--color-black1); 
            text-align: center;
            font-style: normal;
        }
    }
    .modal-header-text{
        margin-top: 20px;
        p{
            font-family: var(--title-font);
            font-weight: 800;
            font-size: 1.625rem;
            line-height: 36px;
            margin: 0;
        }
    }
    .modal-desc{
        margin-bottom: 40px;
        p{
            font-family: var(--body-font);
            font-weight: 400;
            font-size: 1rem;
            line-height: 28.5px;
        }
    }
}

@media screen and (min-width: 992px) and (max-width: 1200px){ 
    .challenge-report-page-home {
        .top-section {
            .counter-card-container {
                &.avg-activity.french {
                    .count-title {
                        font-size: 0.85rem;
                        line-height: 1.9;
                    }
                }

            }
        }
        .breakdown-section {
            .performance-breakdown {
                .title-bar {
                    .title {
                        .pac-tootip-icon {
                            margin-left: 0px;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 992px) {
    .challenge-report-page-home {
        .page-title-container {
            .print-page {
                text-align: left;
                position: inherit;
                .btn {
                    position: inherit;
                }
            }
        }

        .breakdown-section {
            .performance-breakdown {
                .title-bar {
                    .title {
                        &.dropdown {
                            width: 100%;
                        }
                        .pac-tootip-icon {
                            margin-left: 10px;
                        }
                    }
                    .dropdown-div {
                        display: block;
                        padding-bottom: 15px;
                    }
                }
            }
        }
    }
}


