.custom-table {
  padding: 2rem 2rem;
  background: var(--color-white);
  border: 1px solid var(--color-pac-border-grey1);
  border-radius: 5px;
  font-size: 0.875rem;

  table {
    margin-bottom: 0;
  }

  .text-center {
    padding-left: 0;
    padding-right: 0;
  }

  th {
    border: none;
    padding: 0.75rem;
  }

  td {
    border-top: 1px solid var(--color-pac-border-grey);
    padding: 1.5rem;
    padding-left: 0.75rem;
  }

  @media (min-width: 9922px) {
    padding: 3rem;
  }
}
