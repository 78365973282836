.manage-organization-container {
  padding: 2rem 0;

  .copy-code {
    cursor: pointer;
  }

  .org-edit-title-area {
    flex-direction: column;
  }

  select {
    padding: 8px 36px 8px 16px;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url('~/assets/images/dropdown_icon.svg');
    background-repeat: no-repeat;
    background-size: 16px;
    background-position: 95% 50%;
  }

  .org-size-card {
    cursor: default !important;
  }

  .org-size-card-container {
    .org-size-card {
      cursor: default !important;
    }
    border: 1px solid var(--color-pac-border-grey);
    border-radius: 5px;
    margin-bottom: 10px;
    .org-size-card {
      display: inline-block;
      border: none;
      border-right: solid 1px var(--color-pac-border-grey);
      border-radius: 0;
      margin-right: 26px;
    }
    .auto-renew {
      display: inline-block;
      height: 100%;
      vertical-align: middle;
      .date {
        font-weight: 600;
      }
    }
  }
  /* Radio customization in organization edit page starts */

  .profile-title {
    font-family: var(--body-font);
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: -0.015em;
    color: var(--color-black);
  }

  .contactus-link {
    color: var(--color-pac-orange);
  }

  .profile-text {
    font-family: var(--body-font);
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.015em;
    color: var(--color-black1);
    margin-bottom: 0px;
  }

  .profile-text-bold {
    font-weight: 600;
    margin-left: 0.5rem;
  }

  .profile-change-password-page {
    /* margin-top: 3em; */
    /* margin-bottom: 6em; */
  }

  .profile-edit-button-area {
    text-align: center;
    margin-top: 20px;
  }

  .join-us-code {
    color: var(--color-pac-orange);
  }

  .copy-icon {
    height: 18px;
    width: 16px;
    margin-bottom: 4px;
    margin-left: 2px;
  }

  /* =====================all media quries goes from here====================== */

  @media (min-width: 576px) {
    padding: 5rem 2rem;

    .org-edit-title-area {
      flex-direction: row;
    }
  }


  .org-edit-page {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;

    .org-edit-form-title {
      font-family: var(--body-font);
      font-style: normal;
      font-weight: bold;
      font-size: 22px;
      line-height: 28px;
      color: var(--color-black1);
    }

    .org-edit-title-area {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
    }

    .org-edit-form-required-text {
      font-family: var(--body-font);
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 28px;
      text-align: right;
      letter-spacing: 0.015em;
      color: var(--color-black1);
    }

    // .custom-radio .custom-control-input~.custom-control-label::after {
    //   background-image: none;
    //   border: 1px solid #666666;
    //   border-radius: 50%;
    // }

    // .custom-radio .custom-control-input~.custom-control-label::before {
    //   background: var(--color-white);
    // }

    // .custom-radio .custom-control-input:checked~.custom-control-label::after {
    //   background-image: none;
    //   border: 1px solid #666666;
    //   border-radius: 50%;
    // }

    // .custom-radio .custom-control-input:checked~.custom-control-label::before {
    //   background: #666666;
    //   border: 3px solid var(--color-white);
    // }
  }
}

