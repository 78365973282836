.manage-admins-container {
  padding: 1rem 0;

  p.profile-title {
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: -0.015em;
    color: var(--color-black);
  }

  .table-title-container {
    justify-content: space-between;
    display: flex;
  }

  .custom-table {
    margin-top: 1rem;
  }

  .table-container-title {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 36px;
    /* identical to box height, or 200% */
    letter-spacing: -0.015em;

    color: var(--color-black);
  }

  .describe-input {
    height: 100px;
  }

  .admin-title {
    font-family: var(--title-font);
    font-style: normal;
    font-weight: 800;
    font-size: 26px;
    line-height: 36px;
    color: var(--color-black1);
    margin-bottom: 0px;
  }

  .action-delete-link {
    font-family: var(--body-font);
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 0.015em;
    color: var(--color-pac-purple-dark);
    margin-left: auto;
    cursor: pointer;
  }

  .action-link-area {
    display: flex;
    justify-content: space-around;
    height: 70px;
    align-items: center;
  }

  .language-dropdown{
    display: block;
    width: 110px;
    margin: 0 auto;
    position: relative;
    left: 5px;
  }

  td.action-td-invite {
    text-align: center;
  }

  .action-edit-link {
    font-family: var(--body-font);
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    text-align: center;
    letter-spacing: 0.015em;
    color: var(--color-black1);
    margin-right: auto;
    cursor: pointer;
  }

  .invite-resend-btn {
    font-family: var(--body-font);
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.015em;
    color: var(--color-pac-orange);
    text-decoration: underline;
    padding: 0px;
  }

  .admin-edit-page.container-fluid {
    display: flex;
    justify-content: center;
    align-items: unset;
    margin-top: 2em;
  }

  .admin-edit-form-title {
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: -0.015em;
    color: var(--color-black);
  }

  .admin-describe {
    font-family: var(--body-font);
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.015em;
    color: var(--color-black1);
    margin-bottom: 27px;
  }

  .add-admin-button-area {
    justify-content: center;
    align-items: center;
    display: flex;
    height: 100%;
  }

  .admin-th-status,
  .admin-th-action,
  .admin-th-language {
    text-align: center;
  }

  .admin-th-action{
    border-bottom: none;
  }

  .admin-edit-delete-btn-area {
    display: flex;
    justify-content: flex-end;
  }

  .admin-edit-page-delete-btn {
    margin-right: 25px;
    font-family: var(--body-font);
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    display: flex;
    align-items: center;
    text-align: right;
    letter-spacing: 0.015em;
    color: var(--color-input-border-grey);
    cursor: pointer;
  }

  .admin-edit-delete-icon {
    height: 18px;
    width: 18px;
    margin-bottom: 4px;
    margin-right: 6px;
  }

  .tip-row {
    margin-top: 40px;
    .tip {
      padding: 2rem 1rem 2rem 2rem;
      background: var(--color-white);
      border: 1px solid var(--color-pac-border-grey1);
      border-radius: 5px;
      font-size: 0.875rem;

      .icon {
        display: inline-block;
        vertical-align: top;
        width: 61px;
        height: 69px;
      }
      .description {
        display: inline-block;
        padding-left: 20px;
        max-width: 450px;
        .title {
          font-family: var(--title-font);
          font-weight: 600;
          font-style: normal;
          font-size: 1.625rem;
          color: var(--color-black);
        }
        ul {
          padding-left: 20px; 
        }
      }
    }
  }

  @media (min-width: 992px) {
    padding: 2.3125rem 0;

    .custom-table {
      margin-top: 0;
    }
  }
}
