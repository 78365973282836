.profile-page-wrapper {
  padding: 1rem 0;
  flex: 1;

  .profile-page-container {
    max-width: 500px;
  }

  .orange {
    color: var(--color-pac-orange);
  }

  .password-change-form-area.cta-box .content {
    min-height: 550px;
  }

  .profile-title {
    font-family: var(--body-font);
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 28px;
    color: var(--color-black1);
  }

  .profile-text {
    font-family: var(--body-font);
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.015em;
    color: var(--color-black1);
    margin-bottom: 0px;
  }

  .profile-change-password-page {
    display: flex;
    justify-content: center;
    align-items: unset;
    margin-top: 3em;
    align-items: center;
    flex: 1;
  }

  .profile-edit-button-area {
    text-align: center;
    margin-top: 22px;
  }

  .password-change-page.container-fluid {
    display: flex;
    justify-content: center;
    /*min-height: 100vh;*/
    align-items: flex-start;
  }

  .email-data {
    margin-top: .5em;
  }

  .form-area {
    margin-top: 7em;
  }

  .password-form-title {
    font-family: var(--body-font);
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 28px;
    color: var(--color-black1);
    margin-bottom: 5px;
  }

  .change-form-describe {
    font-family: var(--body-font);
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.015em;
    color: var(--color-black1);
    margin-bottom: 5px;
    margin-top: 0.5em;
  }

  li.password-suggestion-li {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.015em;
    color: var(--color-black1);
    display: block;
    margin-left: 5px;
  }

  .social-account-warning-text {
    font-family: var(--body-font);
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.015em;
    color: var(--color-black1);
    margin-bottom: 5px;
    margin-top: 0.5em;

    > div {
      margin-bottom: 1rem;
    }
  }

  @media (min-width: 992px) {
    padding: 2.3125rem 0;
  }
}
